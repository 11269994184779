import React from "react"
import tw, { styled } from "twin.macro"
import { keyframes } from "styled-components"

const scroll = (x) => keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-${x}px);
  }
`

const Styles = styled.div`
  ${tw`relative w-full overflow-hidden`}

  .logos-track {
    ${tw`flex l:flex-wrap l:animate-none l:w-auto l:max-w-l l:mx-auto l:gap-y-5 l:justify-between`}

    animation: ${({ width }) => scroll(width)} ${({ quantity }) =>
      quantity * 3}s linear infinite;
    width: ${({ width }) => width}px;
  }

  .logo {
    ${tw`flex flex-col items-center justify-center flex-shrink-0 flex-grow l:first:pl-0 l:px-2`}
    padding-left: 18px;
    padding-right: 18px;

    &.duplicated {
      ${tw`l:hidden`}
    }

    &.last {
      ${tw`l:pr-0`}
    }

    img {
      height: 35px;
    }
  }
`

const LogosSlider = function ({ logos }) {
  const quantity = logos.length
  const width = logos.reduce((prev, cur) => {
    const width = (35 / cur.height) * cur.width // actual width after resizing
    prev += width + 36 // logo width plus padding
    return prev
  }, 0)

  return (
    <Styles quantity={quantity} width={Math.round(width)}>
      <div className="logos-track">
        {[0, 1].map((i) => {
          return logos.map((logo, lIdx) => (
            <div
              className={`logo ${i > 0 ? "duplicated" : ""} ${
                lIdx === logos.length - 1 ? "last" : ""
              }`}
              key={`${i}-${logo.id}`}
            >
              <img src={logo.url} alt={logo.altText} />
            </div>
          ))
        })}
      </div>
    </Styles>
  )
}

export default LogosSlider
