import React from "react"
import { styled } from "twin.macro"

const StyledPicture = styled.picture`
  ${(props) => (props.customCSS ? props.customCSS : "")}
`

const StyledImg = styled.img`
  ${(props) => (props.customCSS ? props.customCSS : "")}
`

export default ({ src, className = "" }) => {
  if (src.mimeType && src.handle) {
    const webp = `${process.env.GATSBY_ASSETS_BASE_URL}no_metadata/output=format:webp/cache=expiry:max/${src.handle}`
    return (
      <StyledPicture customCSS={src.customCss || null} className={className}>
        <source srcSet={webp} type="image/webp" />
        <source srcSet={src.url} type={src.mimeType} />
        <img src={src.url} alt={src.alt || null} />
      </StyledPicture>
    )
  } else {
    return (
      <StyledImg
        src={src.url}
        className={className}
        alt={src.alt || null}
        customCSS={src.customCss || null}
      />
    )
  }
}
