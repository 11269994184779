import React from "react"
import tw, { styled } from "twin.macro"

import HeaderBg from "images/bg/newsroom-header-bg.jpeg"
import Button from "components/button"
import Img from "components/img"

const Styles = styled.div`
  ${tw`py-16 bg-center`}

  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.8) 0.09%,
      rgba(0, 0, 0, 0) 99.8%
    ),
    linear-gradient(
      87.95deg,
      #3d2164 0.18%,
      rgba(80, 43, 130, 0.76) 41.84%,
      rgba(52, 152, 219, 0.9) 100.3%
    ),
    url(${({ background }) => background});
  background-size: 350%;

  @media (min-width: 640px) {
    background-size: 150%;
  }

  @media (min-width: 1200px) {
    background-size: cover;
  }
`

const NewsroomAssets = ({ contentBlock }) => {
  const { headerContent, icon, callToAction, linkUrl } =
    contentBlock

  return (
    <Styles background={HeaderBg}>
      <div
        className="text-white text-center text-2xl m:text-4xl font-display"
        dangerouslySetInnerHTML={{
          __html: headerContent.html,
        }}
      />

      <div className="text-center mt-10">
        <Button color="green" to={linkUrl}>
          <div className="flex flex-row items-center">
            <Img src={icon} className="mr-3" />
            {callToAction}
          </div>
        </Button>
      </div>
    </Styles>
  )
}

export default NewsroomAssets
