import React from "react"
import tw, { styled } from "twin.macro"

import StyledBlock from "components/newsroom/styled-block"
import CompanyBg from "images/bg/demand-academy-body.jpg"
import VideoModal from "components/sections/video-modal"

const Styles = styled.div`
  ${tw`pt-20 pb-32 m:pt-44 m:pb-48 bg-center`}

  background-image: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 43.53%),
    url(${({ background }) => background});

  background-size: 185% auto;

  @media (min-width: 640px) {
    background-size: 220% auto;
  }

  @media (min-width: 1200px) {
    background-size: 100% auto;
  }

  .content-wrapper {
    ${tw`mx-auto l:max-w-l`}

    @media (max-width: 1200px) {
      max-width: 90%;
    }

    ${StyledBlock} {
      ${tw`text-white mx-auto`}

      max-width: 900px;
    }

    & > .video {
      ${tw`mx-auto w-full`}

      @media (min-width: 1200px) {
        max-width: 800px;
      }
    }
  }
`

const NewsroomVideo = ({ contentBlock }) => {
  const { headerContent, extraData } = contentBlock

  return (
    <Styles background={CompanyBg}>
      <div className="content-wrapper">
        <StyledBlock
          className="secondary-section-header"
          dangerouslySetInnerHTML={{
            __html: headerContent.html,
          }}
        />
        <VideoModal
          autoplayHash="why-demand-video"
          className="mt-10 m:mt-16 relative video"
          caption={"Why demand?"}
          data={extraData}
        />
      </div>
    </Styles>
  )
}

export default NewsroomVideo
