import React from "react"
import tw, { styled } from "twin.macro"

import LogosSlider from "components/logos-slider"
import StyledBlock from "components/newsroom/styled-block"
import HeaderBg from "images/bg/newsroom-header-bg.jpeg"
import Img from "components/img"

const Styles = styled.div`
  ${tw`pb-16 m:pb-32`}

  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(
      257.78deg,
      #3d2164 0.33%,
      rgba(52, 152, 219, 0.8) 54.41%,
      rgba(52, 152, 219, 0.9) 100.69%
    ),
    url(${({ background }) => background});

  background-size: cover;
  padding-top: 110px;

  @media (min-width: 640px) {
    padding-top: 166px;
  }

  .content-wrapper {
    ${tw`mx-auto l:max-w-l`}

    @media (max-width: 1200px) {
      max-width: 90%;
    }

    .info-wrapper {
      ${tw`relative flex flex-col l:flex-row items-center overflow-hidden`}

      ${StyledBlock} {
        ${tw`text-white w-full`}

        margin-bottom: 343px;

        @media (min-width: 640px) {
          margin-bottom: 465px;
        }

        @media (min-width: 1200px) {
          margin-bottom: 174px;
          width: 45%;
        }

        h1 {
          ${tw`l:text-left`}
        }

        p {
          ${tw`l:text-left`}
        }
      }

      .hero {
        ${tw`absolute flex flex-col items-center`}

        top: 270px;
        width: 483px;

        @media (min-width: 640px) {
          top: 244px;
          width: 780px;
        }

        @media (min-width: 1200px) {
          top: -33px;
          right: -100px;
        }
      }
    }
  }
`

const NewsroomHeader = ({ page, logos }) => {
  const { headerContent, featuredImage } = page

  return (
    <Styles background={HeaderBg}>
      <div className="content-wrapper">
        <div className="info-wrapper">
          <StyledBlock
            className="page-header"
            dangerouslySetInnerHTML={{
              __html: headerContent.html,
            }}
          />
          <div className="hero">
            <Img src={featuredImage} />
          </div>
        </div>
      </div>
      {logos && <LogosSlider logos={logos} />}
    </Styles>
  )
}

export default NewsroomHeader
