import React from "react"
import tw, { styled } from "twin.macro"

import StyledBlock from "components/newsroom/styled-block"
import CompanyBg from "images/bg/company.jpg"

const Styles = styled.div`
  ${tw`py-20 pb-16 m:pt-44 m:pb-44 bg-center`}

  background-image: linear-gradient(
    0deg,
    rgba(105, 57, 171, 0.5),
    rgba(105, 57, 171, 0.5)
    ),
    url(${({ background }) => background});
  background-blend-mode: multiply, normal;
  background-size: 185% auto;

  @media (min-width: 640px) {
    background-size: 115% auto;
  }

  @media (min-width: 1200px) {
    background-size: 100% auto;
  }

  .content-wrapper {
    ${tw`mx-auto`}

    max-width: 90%;

    @media (min-width: 1200px) {
      max-width: 900px;
    }

    ${StyledBlock} {
      ${tw`text-white`}
    }
  }
`

const NewsroomAbout = ({ contentBlock }) => {
  return (
    <Styles background={CompanyBg}>
      <div className="content-wrapper">
        <StyledBlock
          className="secondary-section-header"
          dangerouslySetInnerHTML={{
            __html: contentBlock.headerContent.html,
          }}
        />
      </div>
    </Styles>
  )
}

export default NewsroomAbout
