import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import tw, { styled } from "twin.macro"

import Logo from "images/logos/demand360-lite-product.png"
import Button from "components/button"

export const Demand360LiteStyles = styled.div`
  ${tw`flex flex-col items-center w-full l:w-4/5 mx-auto m:max-w-m l:max-w-l text-white overflow-hidden`}

  .logo {
    width: 351px;
  }

  h1 {
    ${tw`font-bold text-center`}

    font-size: 27px;
    line-height: 40px;

    @media (min-width: 640px) {
      ${tw`font-extrabold`}

      font-size: 60px;
      line-height: 75px;
    }
  }

  .description {
    ${tw`text-priority-darkP2`}
    .highlight {
      ${tw`text-white`}
    }
  }

  h5 {
    ${tw`font-normal text-center mt-4`}

    font-size: 22px;
    line-height: 33px;
  }

  .hero {
    margin-bottom: -80px;

    @media (min-width: 640px) {
      margin-bottom: -180px;
    }

    @media (min-width: 1200px) {
      max-width: 990px;
      margin-bottom: -240px;
    }
  }

  &.press {
    ${tw`l:max-w-l`}

    max-width: 90%;

    @media (max-width: 1200px) {
      max-width: 800px;
    }

    h1 {
      @media (min-width: 640px) {
        font-size: 48px;
        line-height: 60px;
      }
    }

    .hero {
      @media (max-width: 640px) {
        margin-bottom: -40px;
      }

      @media (min-width: 640px) {
        margin-bottom: -120px;
      }

      @media (min-width: 1200px) {
        margin-bottom: -190px;
      }
    }
  }
`

const Demand360Lite = ({ target = "user" }) => {
  const query = graphql`
    query Demand360LiteAds {
      gcms {
        contentBlocks(
          where: {
            identifier_in: [
              "demand360-products-lite-ad"
              "demand360-products-lite-ad-newsroom"
            ]
          }
        ) {
          title
          headerContent {
            html
          }
          linkUrl
          callToAction
          featuredImage {
            url
          }
          identifier
        }
      }
    }
  `

  const data = useStaticQuery(query)
  const { contentBlocks } = data.gcms

  const liteAdUser = contentBlocks.find(
    ({ identifier }) => identifier === "demand360-products-lite-ad"
  )
  const liteAdPress = contentBlocks.find(
    ({ identifier }) => identifier === "demand360-products-lite-ad-newsroom"
  )

  const liteAd = target === "press" ? liteAdPress : liteAdUser

  if (!liteAd) {
    return null
  }

  return (
    <Demand360LiteStyles className={target}>
      <div className="mx-4 l:mx-0 flex flex-col items-center">
        <img className="logo" src={Logo} />
        <h1 className="mt-14">{liteAd.title}</h1>
        <div
          className="description mt-3"
          dangerouslySetInnerHTML={{
            __html: liteAd.headerContent.html,
          }}
        />
        <div className="pt-4 m:pt-0 flex flex-col l:flex-row justify-center items-center w-4/5 l:w-1/2 l:space-x-4 mt-12">
          <Button to="/contact" className="my-3 w-full secondary">
            Contact Us
          </Button>
          <Button to={liteAd.linkUrl} className="my-3 w-full">
            {liteAd.callToAction}
          </Button>
        </div>
      </div>
      {liteAd.featuredImage && (
        <Link to={liteAd.linkUrl}>
          <img className="hero mt-12" src={liteAd.featuredImage.url} />
        </Link>
      )}
    </Demand360LiteStyles>
  )
}

export default Demand360Lite
