import React, { useState, useEffect, useRef } from "react"
import ReactDOM from "react-dom"
import tw, { styled } from "twin.macro"
import HubspotForm from "react-hubspot-form"
import { motion, AnimatePresence } from "framer-motion"

import StyledBlock from "components/newsroom/styled-block"
import Button from "components/button"
import SubscribeBg from "images/bg/newsroom-subscribe-bg.jpeg"
import ManualModal from "components/manual-modal"
import { useMounted } from "utils/use-mounted"
import { getItem, setItem } from "utils/storage"
import IconClose from "images/icons/close.inline.svg"

const SUBSCRIPTION_SUBMITTED_STORAGE_KEY = "newsroom-subscription-submitted"

const Styles = styled.div`
  ${tw`text-white py-20 m:py-44`}

  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0.49) 100%
    ),
    linear-gradient(
      108.42deg,
      #000000 -0.14%,
      rgba(70, 0, 175, 0.69) 48.48%,
      rgba(40, 169, 255, 0.7) 100.46%
    ),
    url(${({ background }) => background});
  background-size: cover;

  .content-wrapper {
    ${tw`mx-auto l:max-w-l`}

    max-width: 90%;

    ${StyledBlock}.secondary-section-header {
      ${tw`mx-auto`}

      @media (min-width: 1200px) {
        max-width: 900px;
      }
    }

    .highlight {
      ${tw`mt-16 m:mt-24 flex flex-col l:flex-row l:odd:flex-row-reverse l:space-x-10 l:odd:space-x-reverse`}

      ${StyledBlock} {
        ${tw`mt-1`}

        h3 {
          ${tw`text-left`}
        }

        p {
          ${tw`text-sm m:text-lg m:leading-normal mt-3 m:mt-4`}

          line-height: 175%;
        }
      }
    }
  }
`

const Hubspot = styled.div`
  ${tw`overflow-hidden`}

  .form-columns-1,
  .form-columns-2 {
    ${tw`w-full mx-auto`}

    max-width: 670px;
  }

  fieldset.form-columns-1 .input {
    input[type="email"],
    input[type="text"],
    select,
    textarea {
      ${tw`w-full`}
    }
  }

  fieldset.form-columns-2 .hs-form-field:first-child .input {
    ${tw`m:mr-6`}
  }

  input[type="email"],
  input[type="text"],
  select {
    height: 48px;
  }

  .hs-form {
    ${tw`text-black w-full mx-auto text-base`}

    legend,
    label {
      margin: 0;
    }
  }

  .hs-input {
    ${tw`pl-2`}
  }

  .hs-field-desc,
  .hs-form label {
    ${tw`text-sm l:text-base font-body text-left`}

    &.hs-error-msg {
      ${tw`text-neonred text-xs`}
    }
  }

  .hs-error-msgs {
    ${tw`pb-0 pt-0`}
  }

  .hs-fieldtype-text,
  .hs-fieldtype-select,
  .hs-fieldtype-textarea,
  .hs-fieldtype-booleancheckbox {
    label,
    legend {
      ${tw`font-display pb-0`}

      line-height: 175%;
    }
  }

  .hs-form .hs_error_rollup label {
    ${tw`hidden`}
  }

  .hs-form-booleancheckbox {
    ${tw`pt-4 pb-0 text-xs m:text-sm`}

    label {
      ${tw`flex flex-row justify-center`}

      span {
        ${tw`text-xs m:text-sm`}
      }
    }
  }

  .hs-form fieldset .hs_opt_in_privacy_checkbox.hs-form-field {
    ${tw`pt-8`}
  }

  .hs-form .field {
    ${tw`p-0 pt-6`}
  }

  .hs-form .hs-button {
    ${tw`w-1/3 m:w-1/4 bg-yellow rounded p-3 px-4 uppercase font-display text-grey-5 text-sm flex-shrink block mx-auto`}

    background: linear-gradient(360deg, #C69724 -3.44%, #F7BC2D 102.05%);
    box-shadow: none;
    border: none;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    text-shadow: none;
    height: auto;
    width: auto;
  }

  .actions {
    ${tw`flex flex-col items-center m:items-start mt-6`}
  }
`

const FormStyles = styled.div`
  ${tw`w-full text-priority-darkP1 text-center px-4`}

  max-width: 900px;
`

const SubscriptionForm = ({ close, contentBlock }) => {
  return (
    <FormStyles>
      <StyledBlock
        className="secondary-section-header"
        dangerouslySetInnerHTML={{
          __html: contentBlock.headerContent.html,
        }}
      />
      <Hubspot className="mt-6 m:mt-10">
        <HubspotForm
          portalId={process.env.GATSBY_HUBSPOT_ID}
          formId={process.env.GATSBY_HUBSPOT_NEWSROOM_SUBSCRIPTION_FORM}
          loading={<div>Loading...</div>}
          formInstanceId="newsroom-subscription-form"
          onBeforeFormInit={(ctx) => {
            setTimeout(() => {
              const emailField = document.querySelector(
                `${ctx.target} input[name=email]`
              )

              if (emailField?.value) {
                close()
              }
            }, 200)
          }}
        />
      </Hubspot>
    </FormStyles>
  )
}

const BannerStyles = styled(motion.div)`
  ${tw`fixed bottom-0 left-0 w-screen bg-black bg-opacity-90 z-10`}

  height: 72px;

  .banner-wrapper {
    ${tw`l:max-w-l h-full flex flex-row justify-center items-center mx-auto pl-2 l:pl-0 pr-10 l:pr-0`}

    .text {
      ${tw`text-white text-sm m:text-base font-body`}
    }

    .action {
      ${tw`ml-6 m:ml-8 l:ml-16 flex-shrink-0`}
    }
  }
`

const Banner = ({ onClick, onDismiss, visible }) => {
  if (typeof document === `undefined`) {
    return null
  }

  return ReactDOM.createPortal(
    <AnimatePresence>
      {visible && (
        <BannerStyles
          key="newsroom-subscribe-banner"
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="banner-wrapper">
            <div className="text">
              Sign up for our press distribution list and get access to the
              latest press updates
            </div>
            <div className="action">
              <Button className="small" color="green" onClick={onClick}>
                Sign Up
              </Button>
            </div>
          </div>
          <button
            onClick={onDismiss}
            className="absolute top-6 right-1 m:right-3 l:right-6"
          >
            <IconClose />
          </button>
        </BannerStyles>
      )}
    </AnimatePresence>,
    document.getElementById("modal-container")
  )
}

const CloseButton = ({ onClose }) => {
  if (typeof document === "undefined") {
    return null
  }

  return ReactDOM.createPortal(
    <button onClick={onClose} className="fixed top-0 right-0 m-6 m:m-8 z-50">
      <IconClose />
    </button>,
    document.getElementById("modal-container")
  )
}

const NewsroomSubscribe = ({
  contentBlock,
  modalContentBlock,
  highlights,
  onModalChange,
}) => {
  const [modal, setModal] = useState(false)
  const [subscribed, setSubscribed] = useState(false)
  const [showReminder, setShowReminder] = useState(false)
  const [dismissed, setDismissed] = useState(false)

  const isMounted = useMounted()

  const sectionRef = useRef()

  const showBanner = !subscribed && showReminder && !dismissed && !modal

  useEffect(() => {
    if (getItem(SUBSCRIPTION_SUBMITTED_STORAGE_KEY)) {
      setSubscribed(true)
    }
  }, [modal])

  useEffect(() => {
    window.addEventListener("message", subscriptionSubmitHandler)
    return () => {
      window.removeEventListener("message", subscriptionSubmitHandler)
    }
  }, [])

  useEffect(() => {
    document.addEventListener("scroll", scrollHandler)
    return () => {
      document.removeEventListener("scroll", scrollHandler)
    }
  }, [])

  function subscriptionSubmitHandler(event) {
    if (
      event.data.type === "hsFormCallback" &&
      event.data.eventName === "onFormSubmitted"
    ) {
      if (
        event.data.id === process.env.GATSBY_HUBSPOT_NEWSROOM_SUBSCRIPTION_FORM
      ) {
        setTimeout(() => {
          afterSubscriptionCb()
        }, 2000)
      }
    }
  }

  function scrollHandler() {
    const rect = sectionRef.current?.getBoundingClientRect()

    setShowReminder(rect?.bottom < -920)
  }

  function afterSubscriptionCb() {
    setItem(SUBSCRIPTION_SUBMITTED_STORAGE_KEY, true)
    updateModalState(false)
  }

  function updateModalState(state) {
    setModal(state)
    onModalChange(state)
  }

  return (
    <>
      <Styles background={SubscribeBg} ref={sectionRef}>
        <div className="content-wrapper">
          <StyledBlock
            className="secondary-section-header"
            dangerouslySetInnerHTML={{
              __html: contentBlock.headerContent.html,
            }}
          />

          <div className="text-center mt-10 m:mt-16">
            <Button color="green" onClick={() => updateModalState(true)}>
              Sign up
            </Button>
          </div>

          {highlights.map((cb) => (
            <div key={cb.id} className="highlight">
              <div className="w-full l:w-1/2">
                <div className="flex flex-row items-center">
                  <img className="w-5 h-4 mb-1" src={cb.icon.url} alt="" />
                  <p className="text-white text-sm ml-2">{cb.label}</p>
                </div>
                <StyledBlock
                  className=""
                  dangerouslySetInnerHTML={{
                    __html: cb.headerContent.html,
                  }}
                />
              </div>
              <div className="w-full l:w-1/2 mt-6 m:mt-10 l:mt-0">
                <img src={cb.featuredImage.url} alt="" />
              </div>
            </div>
          ))}

          {!subscribed && (
            <div className="text-center mt-16 m:mt-24">
              <Button color="green" onClick={() => updateModalState(true)}>
                Sign up for press list
              </Button>
            </div>
          )}
        </div>
      </Styles>

      {isMounted && (
        <>
          <ManualModal
            open={modal}
            onClose={() => updateModalState(false)}
            closeButton={false}
            className="newsroom"
            zIndex={50}
          >
            <div className="flex flex-column items-center justify-center mt-16 mb-8 l:my-20">
              <SubscriptionForm
                close={() => afterSubscriptionCb()}
                contentBlock={modalContentBlock}
              />
            </div>
          </ManualModal>
          {modal && (
            <CloseButton onClose={() => updateModalState(false)}></CloseButton>
          )}
        </>
      )}

      {isMounted && (
        <Banner
          onClick={() => updateModalState(true)}
          onDismiss={() => setDismissed(true)}
          visible={showBanner}
        />
      )}
    </>
  )
}

export default NewsroomSubscribe
