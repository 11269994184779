import React from "react"
import tw, { styled, theme } from "twin.macro"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination } from "swiper/modules"

import StyledBlock from "components/newsroom/styled-block"
import SectionBg from "images/bg/waves.jpg"
import Img from "components/img"
import OpeningQuotesImage from "images/icons/opening-quotes.svg"
import LogosSlider from "components/logos-slider"

const Styles = styled.div`
  ${tw`py-20 m:py-44`}

  background: linear-gradient(
      180deg,
      rgba(7, 85, 138, 0.8) 0.24%,
      rgba(36, 0, 89, 0.7) 38.72%,
      rgba(0, 0, 0, 0.8) 99.86%
    ),
    url(${({ background }) => background});

  .content-wrapper {
    ${tw`mx-auto l:max-w-l mb-24 l:mb-16`}

    ${StyledBlock} {
      h2 {
        ${tw`text-white`}
      }
    }

    .header {
      ${tw`mx-auto`}

      @media (max-width: 1200px) {
        max-width: 90%;
      }
    }

    .testimonials {
      ${tw`mt-10 flex flex-row items-stretch relative`}

      @media (min-width: 1024px) {
        ${tw`mx-4`}
      }

      @media (min-width: 1200px) {
        ${tw`mx-0`}
      }

      .swiper-pagination {
        bottom: -50px;
        --swiper-pagination-bullet-size: 8px;
        --swiper-pagination-bullet-inactive-opacity: 1;
        --swiper-pagination-bullet-inactive-color: ${theme`colors.grey.35`};
        --swiper-pagination-color: ${theme`colors.priority.darkP2`};
        --swiper-pagination-bullet-horizontal-gap: 8px;
      }
    }

    .testimonial {
      ${tw`rounded-md py-10 px-5 text-white flex flex-col items-center h-full`}

      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.15) 0%,
        rgba(255, 255, 255, 0.07) 100%
      );

      .opening {
        height: 16.5px;
      }

      .quote {
        ${tw`mt-6 text-sm leading-normal text-center font-italic italic`}

        strong {
          ${tw`font-bold text-yellow`}
        }
      }

      .logo {
        ${tw`mt-6`}

        img {
          height: 35px;
        }
      }

      .separator {
        ${tw`mt-6 h-px`}

        width: 131px;
        background: linear-gradient(
          90deg,
          rgba(247, 188, 45, 0) 0.16%,
          #f7bc2d 50.48%,
          rgba(247, 188, 45, 0) 99.59%
        );
      }

      .author {
        ${tw`mt-6 text-center text-sm leading-normal`}

        .name {
          ${tw`uppercase font-display`}
        }

        .position {
          ${tw`text-priority-darkP2`}
        }
      }

      &.highlight {
        ${tw`rounded-none pt-0 px-0 bg-none mx-auto mt-10 m:mt-16`}

        max-width: 600px;

        .quote {
          ${tw`text-lg m:text-xl`}
        }
      }
    }
  }
`

const Testimonial = ({ testimonial, className = "" }) => {
  const { quote, authorName, authorPosition, logo } = testimonial

  return (
    <div className={`testimonial ${className}`}>
      <img className="opening" src={OpeningQuotesImage} />
      <div
        className="quote"
        dangerouslySetInnerHTML={{
          __html: `${quote.html}`,
        }}
      />
      <div className="logo">
        <Img src={logo} />
      </div>
      <div className="separator" />
      <div className="author">
        <p className="name">{authorName}</p>
        <p className="position">{authorPosition}</p>
      </div>
    </div>
  )
}

const NewsroomTestimonials = ({ contentBlock, testimonials, logos }) => {
  const [highlight, ...rest] = testimonials

  const swiperParams = {
    slidesPerView: 1.15,
    spaceBetween: 10,
    slidesOffsetBefore: 25,
    slidesOffsetAfter: 25,
    modules: [Pagination],
    pagination: {
      el: `${Styles} .swiper-pagination`,
      type: "bullets",
      clickable: true,
    },
    breakpoints: {
      640: {
        slidesPerView: 2.25,
        spaceBetween: 16,
        slidesOffsetBefore: 25,
        slidesOffsetAfter: 25,
      },
      1024: {
        slidesPerView: 3,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        pagination: {
          enabled: false,
        },
      },
    },
  }

  return (
    <Styles background={SectionBg}>
      <div className="content-wrapper">
        <div className="header">
          <StyledBlock
            className="secondary-section-header"
            dangerouslySetInnerHTML={{
              __html: contentBlock.headerContent.html,
            }}
          />

          <Testimonial className="highlight" testimonial={highlight} />
        </div>

        <div className="testimonials">
          <Swiper {...swiperParams}>
            {rest.map((testimonial) => (
              <SwiperSlide key={testimonial.id}>
                <Testimonial testimonial={testimonial} />
              </SwiperSlide>
            ))}
          </Swiper>

          <div className="swiper-pagination"></div>
        </div>
      </div>
      {logos && <LogosSlider logos={logos} />}
    </Styles>
  )
}

export default NewsroomTestimonials
