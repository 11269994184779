import React, { useState } from "react"
import { graphql } from "gatsby"
import tw, { styled } from "twin.macro"
// eslint-disable-next-line no-unused-vars
import { PageFragment, InsightsFragment } from "components/fragments"

import Layout from "components/layout"
import SEO from "components/seo"
import { getSharePreviewUrls } from "utils/share-preview"
import SlantedSection from "components/sections/slant"
import NewsroomHeader from "components/newsroom/header"
import NewsroomLeading from "components/newsroom/leading"
import NewsroomSubscribe from "components/newsroom/subscribe"
import NewsroomAnnouncements from "components/newsroom/announcements"
import NewsroomFaq from "components/newsroom/faq"
import NewsroomAssets from "components/newsroom/assets"
import NewsroomPressCoverage from "components/newsroom/press-coverage"
import NewsroomTestimonials from "components/newsroom/testimonials"
import NewsroomAbout from "components/newsroom/about"
import NewsroomVideo from "components/newsroom/video"
import LightSlantedSection from "components/sections/light-slant"
import ArticleShowcase from "components/sections/article-showcase"
import Demand360Lite from "components/demand360-lite"
import StyledSlantedSection from "components/sections/styled-slant"

const Styles = styled.div`
  &.blurred {
    ${tw`filter blur-sm`}
  }
`

const Newsroom = ({ data }) => {
  const [blurred, setBlurred] = useState(false)

  const { gcms } = data
  const { page } = gcms
  const {
    title,
    description,
    path,
    insightsArticleSerieses,
    contentBlocks,
    frequentlyAskedQuestions,
    testimonials,
    resizedClientLogos,
    articleShowcase,
    extraData,
  } = page
  const { logos, extraLogos } = resizedClientLogos || {}

  const leadingCb = contentBlocks.find(
    ({ identifier }) => identifier === "leading-section"
  )
  const leadingSeries = insightsArticleSerieses?.find(
    ({ identifier }) => identifier === "leading-articles"
  )
  const subscribeCb = contentBlocks.find(
    ({ identifier }) => identifier === "subscribe-section-header"
  )
  const subscribeModalCb = contentBlocks.find(
    ({ identifier }) => identifier === "subscribe-section-modal-header"
  )
  const subscribeHighlights = contentBlocks.filter(({ identifier }) =>
    identifier.startsWith("subscribe-section-highlight-")
  )
  const announcementsCb = contentBlocks.find(
    ({ identifier }) => identifier === "company-announcements-section-header"
  )
  const faqCb = contentBlocks.find(
    ({ identifier }) => identifier === "faq-section-content"
  )
  const brandAssetsCb = contentBlocks.find(
    ({ identifier }) => identifier === "brand-assets-section"
  )
  const pressCoverageCb = contentBlocks.find(
    ({ identifier }) => identifier === "press-coverage-section"
  )
  const testimonialsCb = contentBlocks.find(
    ({ identifier }) => identifier === "testimonials-section-header"
  )
  const aboutCb = contentBlocks.find(
    ({ identifier }) => identifier === "about-parrot-section"
  )
  const videoCb = contentBlocks.find(
    ({ identifier }) => identifier === "demand-video-section"
  )

  return (
    <Styles className={blurred ? "filter blur-sm" : ""}>
      <Layout>
        <SEO
          title={title}
          description={description}
          path={path}
          sharePreviewUrls={getSharePreviewUrls(page)}
        />

        <NewsroomHeader page={page} logos={extraLogos} />

        {leadingCb && leadingSeries && (
          <NewsroomLeading contentBlock={leadingCb} series={leadingSeries} />
        )}

        {subscribeCb && subscribeHighlights.length > 0 && (
          <SlantedSection>
            <NewsroomSubscribe
              contentBlock={subscribeCb}
              modalContentBlock={subscribeModalCb}
              highlights={subscribeHighlights}
              onModalChange={(state) => setBlurred(state)}
            />
          </SlantedSection>
        )}

        {announcementsCb && (
          <SlantedSection>
            <NewsroomAnnouncements
              contentBlock={announcementsCb}
              initialPageSize={4}
            />
          </SlantedSection>
        )}

        {faqCb && frequentlyAskedQuestions.length > 0 && (
          <SlantedSection>
            <NewsroomFaq contentBlock={faqCb} faqs={frequentlyAskedQuestions} />
          </SlantedSection>
        )}

        {brandAssetsCb && <NewsroomAssets contentBlock={brandAssetsCb} />}

        {pressCoverageCb && (
          <NewsroomPressCoverage contentBlock={pressCoverageCb} />
        )}

        {testimonialsCb && testimonials.length > 0 && (
          <SlantedSection>
            <NewsroomTestimonials
              contentBlock={testimonialsCb}
              testimonials={testimonials}
              logos={logos}
            />
          </SlantedSection>
        )}

        {aboutCb && (
          <SlantedSection>
            <NewsroomAbout contentBlock={aboutCb} />
          </SlantedSection>
        )}

        {videoCb && (
          <SlantedSection>
            <NewsroomVideo contentBlock={videoCb} />
          </SlantedSection>
        )}

        {articleShowcase?.length > 0 && (
          <LightSlantedSection>
            <div>
              <ArticleShowcase
                articles={articleShowcase}
                title={extraData?.articlesShowcaseTitle}
                className="newsroom"
              />
            </div>
          </LightSlantedSection>
        )}

        <StyledSlantedSection className="z-0 pt-16 m:pt-40 l:pt-44 pb-0 demand360-lite-newsroom">
          <Demand360Lite target="press" />
        </StyledSlantedSection>
      </Layout>
    </Styles>
  )
}

export default Newsroom

export const query = graphql`
  query NewsroomPage {
    gcms {
      page(where: { path: "/newsroom" }) {
        ...PageData
        resizedClientLogos: featuredLogosClients {
          id
          logos {
            id
            url(transformation: { document: { output: { format: webp } } })
            height
            width
            altText
          }
          extraLogos {
            id
            url(transformation: { document: { output: { format: webp } } })
            height
            width
            altText
          }
        }
        insightsArticleSerieses {
          identifier
          insightsArticles {
            ...InsightsData
            pressLogo {
              url(
                transformation: {
                  image: { resize: { width: 250, fit: clip } }
                  document: { output: { format: webp } }
                }
              )
            }
          }
        }
        frequentlyAskedQuestions {
          id
          title
          question {
            html
          }
          answer {
            html
          }
        }
      }
    }
  }
`
