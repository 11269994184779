import React, { useState } from "react"
import tw, { styled, theme } from "twin.macro"
import { motion, AnimatePresence } from "framer-motion"
import { useMediaQuery } from "react-responsive"

import StyledBlock from "components/newsroom/styled-block"
import PlusIcon from "images/icons/plus.inline.svg"
import MinusIcon from "images/icons/minus.inline.svg"
import Img from "components/img"

const Styles = styled.div`
  ${tw`bg-white pt-20 pb-16 m:pt-44 m:pb-32`}

  .content-wrapper {
    ${tw`mx-auto text-grey-5`}

    max-width: 90%;

    @media (min-width: 850px) {
      max-width: 800px;
    }

    .faqs {
      ${tw`divide-y divide-solid divide-grey-83 border-t border-b border-grey-83 mt-10 m:mt-16`}

      .faq {
        ${tw`cursor-pointer py-4 m:py-6`}

        .question-wrapper {
          ${tw`flex flex-row items-start text-sm m:text-lg m:leading-normal font-display`}

          line-height: 175%;

          .icon {
            ${tw`mr-3 mt-1 flex-shrink-0`}

            height: 12px;
            width: 12px;

            &.minus {
              ${tw`hidden`}
            }
          }
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            .question-wrapper {
              .icon {
                &.plus path {
                  fill: ${theme`colors.neonred`};
                }
              }
            }
          }
        }

        &.active {
          .question-wrapper {
            .icon {
              &.minus {
                ${tw`block`}

                path {
                  fill: ${theme`colors.neonred`};
                }
              }

              &.plus {
                ${tw`hidden`}
              }
            }
          }
        }

        .answer {
          ${tw`text-xs m:text-base pl-6`}

          line-height: 175%;

          @media (min-width: 640px) {
            line-height: 175%;
          }

          ul {
            ${tw`list-disc list-inside mt-2`}

            div {
              ${tw`inline`}
            }
          }

          p {
            ${tw`mt-2 first:mt-0`}
          }

          a {
            ${tw`underline`}
          }
        }
      }
    }

    .contact {
      ${tw`mt-16`}

      ${StyledBlock}, .card .info .person {
        h3 {
          ${tw`text-neonred text-left mb-3 m:mb-5`}
        }

        p {
          ${tw`text-sm m:text-base`}

          line-height: 175%;

          @media (min-width: 640px) {
            line-height: 175%;
          }

          a,
          strong {
            ${tw`font-display`}
          }

          &.small {
            ${tw`m:text-xs`}

            font-size: 10px;
            line-height: 175%;

            @media (min-width: 640px) {
              line-height: 175%;
            }
          }
        }
      }

      .card {
        ${tw`flex flex-row mt-4 m:mt-0`}

        .picture {
          ${tw`flex-shrink-0`}

          width: 100px;
          height: 100px;

          @media (min-width: 640px) {
            width: 180px;
            height: 180px;
          }

          img {
            ${tw`rounded-md`}
          }
        }

        .info {
          ${tw`ml-4 m:ml-6 flex flex-col justify-start m:justify-between`}
        }
      }
    }
  }
`

const NewsroomFaq = ({ contentBlock, faqs }) => {
  const [activeFaq, setActiveFaq] = useState(null)

  const isTablet = useMediaQuery({ minWidth: 640 })

  const { headerContent, mainContent, featuredImage, extraData } = contentBlock

  function handleFaqClick(faqId) {
    if (activeFaq === faqId) {
      setActiveFaq(null)
    } else {
      setActiveFaq(faqId)
    }
  }

  return (
    <Styles>
      <div className="content-wrapper">
        <StyledBlock
          dangerouslySetInnerHTML={{
            __html: headerContent.html,
          }}
        />
        <div className="faqs">
          {faqs.map((faq) => (
            <div
              className={`faq ${activeFaq === faq.id ? "active" : ""}`}
              key={faq.id}
              onClick={() => handleFaqClick(faq.id)}
            >
              <div className="question-wrapper">
                <PlusIcon className="icon plus" />
                <MinusIcon className="icon minus" />
                <div
                  className="question"
                  dangerouslySetInnerHTML={{
                    __html: faq.question.html,
                  }}
                />
              </div>
              <AnimatePresence>
                {activeFaq === faq.id && (
                  <motion.div
                    className="answer"
                    dangerouslySetInnerHTML={{
                      __html: faq.answer.html,
                    }}
                    animate={{
                      opacity: 1,
                      height: "auto",
                      marginTop: isTablet ? 24 : 16,
                    }}
                    initial={{ opacity: 0, height: 0, marginTop: 0 }}
                    exit={{ opacity: 0, height: 0, marginTop: 0 }}
                    transition={{ duration: 0.3, ease: "easeOut" }}
                  />
                )}
              </AnimatePresence>
            </div>
          ))}
        </div>

        <div className="contact">
          <StyledBlock
            className="m:hidden"
            dangerouslySetInnerHTML={{
              __html: mainContent.html,
            }}
          />
          <div className="card">
            <div className="picture">
              <Img src={featuredImage} />
            </div>
            <div className="info">
              <StyledBlock
                className="hidden m:block"
                dangerouslySetInnerHTML={{
                  __html: mainContent.html,
                }}
              />
              <div className="person">
                <p>
                  <strong>{extraData?.contact?.name}</strong>
                </p>
                <p className="small">{extraData?.contact?.position}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Styles>
  )
}

export default NewsroomFaq
