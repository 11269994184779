import React, { useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import tw, { styled } from "twin.macro"
import algoliasearch from "algoliasearch/lite"
import {
  InstantSearch,
  Configure,
  connectInfiniteHits,
  connectStateResults,
} from "react-instantsearch-dom"
import moment from "moment"

import StyledBlock from "components/newsroom/styled-block"

const NEWS_FILTER = "categories:'Industry News'"
const NEWS_PAGE_SIZE = 10
const NEWS_INITIAL_PAGE_SIZE = 5

const algoliaClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const Styles = styled.div`
  ${tw`pt-16 pb-12 m:pb-36 m:pt-32 bg-grey-95`}

  .content-wrapper {
    ${tw`l:max-w-l mx-auto`}

    max-width: 90%;

    .news {
      ${tw`mt-10 m:mt-16 flex flex-col l:flex-row l:justify-between l:flex-wrap`}

      .new {
        ${tw`relative hidden l:flex flex-row w-full flex-shrink-0 mb-3 m:mb-4`}

        @media (min-width: 1200px) {
          width: 49%;
        }

        &.visible {
          ${tw`flex`}
        }

        .image {
          ${tw`flex-shrink-0 rounded-l-md`}

          width: 95px;
          height: 95px;

          @media (min-width: 640px) {
            width: 221px;
            height: 129px;
          }

          img {
            ${tw`object-cover w-full h-full rounded-l-md`}

            transition: all 0.15s ease-out;
          }
        }

        .content {
          ${tw`bg-white px-4 py-3 rounded-r-md w-full`}

          .extra {
            ${tw`text-xs m:text-sm leading-none m:leading-none mt-2 m:mt-3 flex flex-row items-center`}

            .publisher {
              ${tw`text-neonred font-display truncate inline-block`}

              max-width: 150px;

              @media (min-width: 640px) {
                max-width: 300px;
              }

              @media (min-width: 1200px) {
                max-width: 220px;
              }
            }

            .divider {
              ${tw`mx-2 m:mx-3 text-grey-35`}

              @media (min-width: 640px) {
                margin-bottom: 2px;
              }
            }

            .date {
              ${tw`text-grey-35 inline-block`}
            }
          }
        }

        ${StyledBlock} {
          h5 {
            ${tw`text-left line-clamp-3`}
          }
        }

        @media (hover: hover) and (pointer: fine) {
          .image {
            ${tw`overflow-hidden`}
          }

          &:hover .image img {
            transform: scale(1.1);
          }
        }
      }
    }

    .load-more {
      button {
        ${tw`bg-white rounded-md`}

        &:disabled {
          background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.1),
              rgba(0, 0, 0, 0.1)
            ),
            #ffffff;
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.1),
                rgba(0, 0, 0, 0.1)
              ),
              #ffffff;
          }
        }
      }
    }
  }
`

function getHitImageUrl(handle) {
  const mediaBase = process.env.GATSBY_ASSETS_BASE_URL
  return `${mediaBase}auto_image/resize=width:1200/compress/${handle}`
}

const LoadMore = ({ isSearchStalled, onClick }) => {
  return (
    <div className="load-more">
      <button
        className="mb-8 bg-white py-2 pt-3 px-0 text-center uppercase font-display text-grey-45 block w-full outline-none"
        onClick={onClick}
        disabled={isSearchStalled}
      >
        Load More
      </button>
    </div>
  )
}

const ConnectedLoadMore = connectStateResults(LoadMore)

const NewsHits = ({
  hits,
  hasMore,
  refineNext,
  onFirstPage,
  fallbackImage,
}) => {
  function isVisible(index) {
    return !onFirstPage || index < NEWS_INITIAL_PAGE_SIZE
  }

  return (
    <>
      <div className="news">
        {hits.map((hit, index) => (
          <Link
            to={hit.path}
            className={`new ${isVisible(index) ? "visible" : ""}`}
            key={hit.objectID}
          >
            <div className="image">
              <img
                src={getHitImageUrl(hit.featuredImage)}
                onError={(e) => {
                  e.target.src = fallbackImage
                }}
              />
            </div>
            <StyledBlock className="content smaller">
              <h5>{hit.title}</h5>
              <p className="extra">
                {hit.publisher && (
                  <>
                    <span className="publisher">{hit.publisher}</span>
                    <span className="divider">|</span>
                  </>
                )}
                <span className="date">
                  {moment(new Date(hit.date)).format("MMM D, YYYY")}
                </span>
              </p>
            </StyledBlock>
          </Link>
        ))}
      </div>
      {hasMore && <ConnectedLoadMore onClick={refineNext} />}
    </>
  )
}

const InfiniteNewsHits = connectInfiniteHits(NewsHits)

const NewsroomPressCoverage = ({ contentBlock }) => {
  const data = useStaticQuery(graphql`
    query PressCoverageAnnouncements {
      gcms {
        pressCoverageConfig: websiteSetting(
          where: { name: "Press Coverage settings" }
        ) {
          jsonValue
        }
      }
    }
  `)

  const { gcms } = data
  const { pressCoverageConfig } = gcms

  const [onFirstPage, setOnFirstPage] = useState(true)

  const fallbackImage =
    pressCoverageConfig?.jsonValue?.fallbackImage ||
    "/images/insights-fallback.jpg"

  function onSearchStateChange(state) {
    if (state.page > 1) {
      setOnFirstPage(false)
    }
  }

  return (
    <Styles>
      <div className="content-wrapper">
        <StyledBlock
          className="secondary-section-header"
          dangerouslySetInnerHTML={{
            __html: contentBlock.headerContent.html,
          }}
        />

        <InstantSearch
          indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
          searchClient={algoliaClient}
          onSearchStateChange={onSearchStateChange}
        >
          <Configure
            distinct
            hitsPerPage={NEWS_PAGE_SIZE}
            filters={NEWS_FILTER}
          />
          <InfiniteNewsHits
            onFirstPage={onFirstPage}
            fallbackImage={fallbackImage}
          />
        </InstantSearch>
      </div>
    </Styles>
  )
}

export default NewsroomPressCoverage
